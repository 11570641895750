<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Daftar Barang Keluar</h2>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <p class="hp-p1-body mb-0 mx-3 text-black">Semua ({{ totalRows }})</p>
          <b-button variant="primary" to="/master-data/barang-keluar/tambah">
            Tambah Data Barang Keluar
          </b-button>
        </div>
        <div class="d-flex w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="outline-black-50" class="border-right-0 pr-0 pl-2">
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input v-model="searchQuery" type="search" placeholder="Search" class="border-left-0"
              @input="debounceSearch" />
          </b-input-group>
        </div>
      </div>
    </b-col>
    <div class="col-12">
      <b-table style="overflow: auto; white-space: nowrap" :items="tableItems" :fields="fields" :busy="isBusy"
        responsive="sm">
        <template v-slot:cell(outgoing_status)="row">
          <b-badge v-if="row.item.outgoing_status" :variant="row.item.outgoing_status === 'outgoing'
              ? 'primary'
              : row.item.outgoing_status === 'return'
                ? 'warning'
                : ''
            ">
            {{
              row.item.outgoing_status === "outgoing"
                ? "Barang Keluar"
                : row.item.outgoing_status === "return"
                  ? "Return"
                  : row.item.outgoing_status
            }}
          </b-badge>
          <br />
          <b-badge v-if="row.item.data_return === 1" variant="danger">
            Pengambalian
          </b-badge>
        </template>
        <template v-slot:cell(aksi)="row">
          <b-button class="mr-3" variant="info-1" :to="`/master-data/barang-keluar/detail/${row.item.no}`">
            <i class="ri-eye-line mr-1" />
            Detail
          </b-button>

          <b-button variant="success" :to="`/master-data/barang-keluar/cetak-nota/${row.item.no}`">
            <i class="ri-printer-fill mr-1" />
            Cetak
          </b-button>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
      </b-table>
      <b-pagination v-model="currentPage" class="mb-3" align="right" :per-page="perPage" :total-rows="totalRows"
        @input="changePage" />
    </div>
    <form-edit-barang-keluar :selected-item="selectedItem" />
  </b-row>
</template>

<script>
import {
  BButton,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BSpinner,
  BTable,
  BPagination,
  BBadge,
} from "bootstrap-vue";
import debounce from "lodash/debounce";
import outgoingGoodsAPI from "../../../../../api/outgoinggoods/outgoingGoodsAPI";

export default {
  name: "ListOutgoingStockGoods",
  components: {
    BSpinner,
    BPagination,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BRow,
    BInputGroup,
    BCol,
    BTable,
    BBadge,
  },
  data() {
    return {
      isBusy: false,
      selectedItem: {},
      fields: [
        { key: "outgoing_status", label: "Status", sortable: true },
        { key: "created_at", label: "Tanggal Dibuat", sortable: true },
        { key: "no", label: "Nomor Nota", sortable: true },
        { key: "requester", label: "Pemohon", sortable: true },
        { key: "note", label: "Note", sortable: true },
        { key: "aksi", label: "Aksi" },
      ],
      currentPage: 1,
      perPage: 10,
      searchQuery: "",
      totalRows: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isBusy = true;
      try {
        const { data: response } = await outgoingGoodsAPI.getAll();
        this.tableItems = response.data.data;
        this.totalRows = response.data.total;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    debounceSearch: debounce(function () {
      this.fetchData();
    }, 500),
  },
};
</script>
